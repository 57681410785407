<template>
  <div>
    <div class="field has-addons">
      <div class="control is-expanded has-icons-right">
        <input
          type="text"
          class="input"
          v-model="value"
          @keydown.enter="save"
          :placeholder="placeHolder"
        />
      </div>
      <div class="control">
        <button class="button has-text-soft" @click="save">
          <span class="icon is-small is-right">
            <i class="fad fa-plus-circle"></i>
          </span>
        </button>
      </div>
    </div>
    <div class="field is-grouped is-grouped-multiline" v-if="models.length">
      <div class="control" v-for="(model, index) in models" :key="index">
        <div class="tags has-addons is-marginless">
          <span class="tag is-marginless is-primary">
            {{ model }}
          </span>
          <span
            class="tag is-marginless is-light has-text-danger is-delete"
            @click="remove(index)"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchSelect',
  data: () => ({
    models: [],
    value: ''
  }),
  props: {
    preset: {
      type: Array,
      required: false,
      default: () => []
    },
    placeHolder: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  watch: {
    value(value) {
      this.$emit('input', value)
    }
  },
  methods: {
    clearAll() {
      this.models = []
      this.value = ''
    },
    remove(index) {
      this.models.splice(index, 1)
      this.$emit('change', this.models)
    },
    save() {
      const { value } = this
      if (value) {
        this.models.push(value)
        this.value = ''
      }

      this.$emit('change', this.models)
    }
  },
  mounted() {
    if (this.preset.length) {
      this.models = this.preset
    }
  }
}
</script>
